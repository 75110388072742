// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Link from "../../../styleguide/components/Link/Link.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Routes_User from "../../../routes/common/Routes_User.res.js";
import * as Routes_Project from "../../../routes/common/Routes_Project.res.js";
import * as TopNavbarScss from "./TopNavbar.scss";
import * as JsxRuntime from "react/jsx-runtime";

var css = TopNavbarScss;

var userCategories = [
  {
    id: "CloudRack",
    slug: Routes_User.Personal.Dashboard.cloudRack
  },
  {
    id: "Projects",
    slug: Routes_Project.index
  },
  {
    id: "Orders",
    slug: Routes_User.Personal.Dashboard.orders
  },
  {
    id: "Invitations",
    slug: Routes_User.Personal.Dashboard.invitations
  },
  {
    id: "EditProfile",
    slug: Routes_User.Personal.Dashboard.edit
  }
];

var adminCategories = [
  {
    id: "CloudRack",
    slug: Routes_User.Personal.Dashboard.cloudRack
  },
  {
    id: "Projects",
    slug: Routes_Project.index
  },
  {
    id: "Orders",
    slug: Routes_User.Personal.Dashboard.orders
  },
  {
    id: "Invitations",
    slug: Routes_User.Personal.Dashboard.invitations
  },
  {
    id: "EditProfile",
    slug: Routes_User.Personal.Dashboard.edit
  },
  {
    id: "Notifications",
    slug: Routes_User.Personal.Dashboard.notifications
  }
];

function TopNavbar(props) {
  var userRole = props.userRole;
  var selectedCategory = props.selectedCategory;
  var tmp;
  var exit = 0;
  if (userRole === "Admin") {
    tmp = Belt_Array.mapWithIndex(adminCategories, (function (index, category) {
            var match = category.id;
            return JsxRuntime.jsx("div", {
                        children: JsxRuntime.jsx(Link.make, {
                              href: category.slug,
                              className: css.linkItem,
                              children: match === "CloudRack" ? "CloudRack™" : (
                                  match === "Notifications" ? "Notifications" : (
                                      match === "Orders" ? "Orders" : (
                                          match === "EditProfile" ? "Edit Profile" : (
                                              match === "Projects" ? "Projects" : "Invitations"
                                            )
                                        )
                                    )
                                )
                            }),
                        className: Cx.cx([
                              css.linkContainer,
                              category.id === selectedCategory ? css.underlinedLink : ""
                            ])
                      }, String(index));
          }));
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = Belt_Array.mapWithIndex(userCategories, (function (index, category) {
            var match = category.id;
            return JsxRuntime.jsx("div", {
                        children: JsxRuntime.jsx(Link.make, {
                              href: category.slug,
                              className: css.linkItem,
                              children: match === "CloudRack" ? "CloudRack™" : (
                                  match === "Notifications" ? "Notifications" : (
                                      match === "Orders" ? "Orders" : (
                                          match === "EditProfile" ? "Edit Profile" : (
                                              match === "Projects" ? "Projects" : "Invitations"
                                            )
                                        )
                                    )
                                )
                            }),
                        className: Cx.cx([
                              css.linkContainer,
                              category.id === selectedCategory ? css.underlinedLink : ""
                            ])
                      }, String(index));
          }));
  }
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx("div", {
                    children: tmp,
                    className: css.barContainer
                  }),
              className: css.barWrapper
            });
}

var make = TopNavbar;

export {
  css ,
  userCategories ,
  adminCategories ,
  make ,
}
/* css Not a pure module */
