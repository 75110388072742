// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as Label from "../../../styleguide/forms/Label/Label.res.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Checkbox from "../../../styleguide/forms/Checkbox/Checkbox.res.js";
import * as Dropdown from "../../../styleguide/forms/Dropdown/Dropdown.res.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as SearchField from "../../../styleguide/forms/SearchField/SearchField.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProvidersListScss from "../ProvidersList.scss";

var css = ProvidersListScss;

function ProvidersListFilters(props) {
  var onChangeFacilityType = props.onChangeFacilityType;
  var onChangeSearch = props.onChangeSearch;
  var facilityTypeFilter = props.facilityTypeFilter;
  var x = facilityTypeFilter.length;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(SearchField.make, {
                      id: "providers-list-search",
                      value: props.search,
                      placeholder: "Search by provider name",
                      inputClassName: css.searchField,
                      iconClassName: css.searchIcon,
                      onChange: (function ($$event) {
                          onChangeSearch($$event.target.value);
                        })
                    }),
                JsxRuntime.jsxs(Dropdown.make, {
                      children: [
                        JsxRuntime.jsx(Dropdown.Trigger.make, {
                              className: css.dropdownTrigger,
                              iconSize: "XS",
                              iconColor: "Gray",
                              children: x !== 0 ? (
                                  x !== 1 ? String(x) + " Types" : "1 Type"
                                ) : "Provider Type"
                            }),
                        JsxRuntime.jsx(Dropdown.Body.make, {
                              position: {
                                TAG: "Below",
                                _0: "LeftEdge"
                              },
                              className: css.dropdownBody,
                              children: Belt_Array.map(props.facilityTypes, (function (facilityType) {
                                      var id = "providers-list-filter--facility-type-" + ID.toString(facilityType.id);
                                      return JsxRuntime.jsxs("div", {
                                                  children: [
                                                    JsxRuntime.jsx(Checkbox.make, {
                                                          id: id,
                                                          size: "SM",
                                                          checked: (function (__x) {
                                                                return Js_array.findIndex((function (value) {
                                                                              return Caml_obj.equal(value, facilityType.id);
                                                                            }), __x);
                                                              })(facilityTypeFilter) > -1,
                                                          onChange: (function ($$event) {
                                                              onChangeFacilityType(facilityType.id, $$event.target.checked);
                                                            })
                                                        }),
                                                    JsxRuntime.jsx(Label.make, {
                                                          forId: id,
                                                          className: css.label,
                                                          children: facilityType.title
                                                        })
                                                  ],
                                                  className: css.dropdownBodyRow
                                                }, id);
                                    }))
                            })
                      ]
                    })
              ]
            });
}

var make = ProvidersListFilters;

export {
  css ,
  make ,
}
/* css Not a pure module */
