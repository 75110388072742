// Generated by ReScript, PLEASE EDIT WITH CARE

import * as PervasivesU from "rescript/lib/es6/pervasivesU.js";

function fromString(x) {
  switch (x) {
    case "" :
        return "None";
    case "All" :
        return "All";
    case "Bare Metal" :
        return "BareMetal";
    case "Cloud" :
        return "Cloud";
    case "Colocation" :
        return "Colocation";
    case "Internet" :
        return "Internet";
    case "Manage" :
        return "Manage";
    case "Network" :
        return "Network";
    default:
      return PervasivesU.failwith("Unexpected service category type: " + x);
  }
}

function showManage(category) {
  var match = fromString(category);
  if (match === "Manage") {
    return true;
  } else {
    return false;
  }
}

export {
  fromString ,
  showManage ,
}
/* No side effect */
