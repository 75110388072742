// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Button from "../../../styleguide/components/Button/Button.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProvidersListFilters from "../filters/ProvidersListFilters.res.js";
import * as ProvidersListScss from "../ProvidersList.scss";
import * as ProvidersListFiltersManage from "../filters/ProvidersListFiltersManage.res.js";
import * as ProvidersListServiceCategory from "../ProvidersListServiceCategory.res.js";

var css = ProvidersListScss;

function ProvidersListHeader(props) {
  var userRole = props.userRole;
  var serviceCategory = props.serviceCategory;
  var onChangeFacilityType = props.onChangeFacilityType;
  var onChangeSearch = props.onChangeSearch;
  var facilityTypeFilter = props.facilityTypeFilter;
  var facilityTypes = props.facilityTypes;
  var search = props.search;
  var tmp;
  if (props.mobile) {
    tmp = props.showFilter ? null : JsxRuntime.jsx(Button.make, {
            size: "XS",
            color: "Teal",
            onClick: props.onClickFilterButton,
            children: "Filters"
          });
  } else {
    var tmp$1;
    tmp$1 = ProvidersListServiceCategory.showManage(serviceCategory) ? (
        userRole === "Admin" ? css.manageAdminFilters : css.manageFilters
      ) : css.filters;
    tmp = JsxRuntime.jsx("div", {
          children: ProvidersListServiceCategory.showManage(serviceCategory) ? JsxRuntime.jsx(ProvidersListFiltersManage.make, {
                  search: search,
                  facilityTypes: facilityTypes,
                  facilityTypeFilter: facilityTypeFilter,
                  permissionTypes: props.permissionTypes,
                  permissionTypeFilter: props.permissionTypeFilter,
                  statusTypes: props.statusTypes,
                  statusTypeFilter: props.statusTypeFilter,
                  onChangeSearch: onChangeSearch,
                  onChangeFacilityType: onChangeFacilityType,
                  onChangePermissionType: props.onChangePermissionType,
                  onChangeStatusType: props.onChangeStatusType,
                  userRole: userRole
                }) : JsxRuntime.jsx(ProvidersListFilters.make, {
                  search: search,
                  facilityTypes: facilityTypes,
                  facilityTypeFilter: facilityTypeFilter,
                  onChangeSearch: onChangeSearch,
                  onChangeFacilityType: onChangeFacilityType
                }),
          className: tmp$1
        });
  }
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx("div", {
                            children: serviceCategory + " Providers",
                            className: css.title
                          }),
                      tmp
                    ],
                    className: css.header
                  }),
              className: css.headerContainer
            });
}

var make = ProvidersListHeader;

export {
  css ,
  make ,
}
/* css Not a pure module */
