// Generated by ReScript, PLEASE EDIT WITH CARE


function fromJs(js) {
  return {
          id: js.id,
          name: js.name,
          displayName: js.displayName
        };
}

export {
  fromJs ,
}
/* No side effect */
