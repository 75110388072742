// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../styleguide/components/Link/A.res.js";
import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as IconOk from "../../../styleguide/icons/IconOk.res.js";
import * as LazyImage from "../../../styleguide/components/LazyImage/LazyImage.res.js";
import * as Cloudinary from "../../../libs/Cloudinary.res.js";
import * as Routes_Provider from "../../../routes/common/Routes_Provider.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Routes_Marketplace from "../../../routes/common/Routes_Marketplace.res.js";
import * as ProviderTileScss from "./ProviderTile.scss";

var css = ProviderTileScss;

function buildLogoUrl(src) {
  return Cloudinary.Url.build("Png", {
              hd: {
                TAG: "Width",
                _0: 300
              },
              tl: /* [] */0
            }, src);
}

var Helpers = {
  buildLogoUrl: buildLogoUrl
};

function ProviderTile(props) {
  var __showAgreementCheck = props.showAgreementCheck;
  var __manageContext = props.manageContext;
  var __deferImage = props.deferImage;
  var __className = props.className;
  var provider = props.provider;
  var className = __className !== undefined ? __className : "";
  var deferImage = __deferImage !== undefined ? __deferImage : false;
  var manageContext = __manageContext !== undefined ? __manageContext : false;
  var showAgreementCheck = __showAgreementCheck !== undefined ? __showAgreementCheck : false;
  var tmp;
  var exit = 0;
  exit = 1;
  if (exit === 1) {
    tmp = JsxRuntime.jsx("div", {
          children: provider.agreement && showAgreementCheck ? JsxRuntime.jsx("div", {
                  children: JsxRuntime.jsx(IconOk.make, {
                        size: "XXS",
                        color: "White"
                      }),
                  className: css.agreementCheck
                }) : null,
          className: Cx.cx([manageContext ? css.leftBadgeWrapper : css.badgeWrapper])
        });
  }
  var tmp$1;
  if (manageContext) {
    var tmp$2;
    tmp$2 = props.userRole === "Admin" ? JsxRuntime.jsx("div", {
            children: JsxRuntime.jsxs(A.make, {
                  href: Routes_Provider.Dashboard.edit(provider.slug),
                  className: css.item,
                  children: [
                    JsxRuntime.jsx("div", {
                          children: "Master"
                        }),
                    JsxRuntime.jsx("div", {
                          children: provider.master,
                          className: css.total
                        })
                  ]
                }),
            className: css.itemContainer
          }) : null;
    tmp$1 = JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            JsxRuntime.jsx("div", {
                  children: JsxRuntime.jsxs(A.make, {
                        href: Routes_Provider.Dashboard.edit(provider.slug),
                        className: css.item,
                        children: [
                          JsxRuntime.jsx("div", {
                                children: "Managers"
                              }),
                          JsxRuntime.jsx("div", {
                                children: String(provider.managersCount),
                                className: css.total
                              })
                        ]
                      }),
                  className: css.itemContainer
                }),
            JsxRuntime.jsx("div", {
                  children: JsxRuntime.jsxs(A.make, {
                        href: Routes_Provider.Dashboard.edit(provider.slug),
                        className: css.item,
                        children: [
                          JsxRuntime.jsx("div", {
                                children: "Support"
                              }),
                          JsxRuntime.jsx("div", {
                                children: provider.supportNumber,
                                className: css.total
                              })
                        ]
                      }),
                  className: css.itemContainer
                }),
            tmp$2
          ]
        });
  } else {
    tmp$1 = null;
  }
  return JsxRuntime.jsxs("div", {
              children: [
                tmp,
                JsxRuntime.jsx(A.make, {
                      href: manageContext ? Routes_Provider.Dashboard.edit(provider.slug) : provider.url,
                      className: css.logoContainer,
                      children: deferImage ? JsxRuntime.jsx(LazyImage.make, {
                              src: buildLogoUrl(provider.logo),
                              height: 100,
                              className: css.logo
                            }) : JsxRuntime.jsx("img", {
                              className: css.logo,
                              src: buildLogoUrl(provider.logo)
                            })
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        provider.totalLocations > 0 && !manageContext ? JsxRuntime.jsx("div", {
                                children: JsxRuntime.jsxs(A.make, {
                                      href: Routes_Provider.$$Location.index(provider.slug),
                                      className: css.item,
                                      children: [
                                        JsxRuntime.jsx("div", {
                                              children: "Locations"
                                            }),
                                        JsxRuntime.jsx("div", {
                                              children: String(provider.totalLocations),
                                              className: css.total
                                            })
                                      ]
                                    }),
                                className: css.itemContainer
                              }) : null,
                        provider.totalAllLocations > 0 && manageContext ? JsxRuntime.jsx("div", {
                                children: JsxRuntime.jsxs(A.make, {
                                      href: Routes_Provider.Dashboard.edit(provider.slug),
                                      className: css.item,
                                      children: [
                                        JsxRuntime.jsx("div", {
                                              children: "All Locations"
                                            }),
                                        JsxRuntime.jsx("div", {
                                              children: String(provider.totalAllLocations),
                                              className: css.total
                                            })
                                      ]
                                    }),
                                className: css.itemContainer
                              }) : null,
                        tmp$1,
                        provider.totalProducts > 0 ? JsxRuntime.jsx("div", {
                                children: JsxRuntime.jsxs(A.make, {
                                      href: Routes_Marketplace.Provider.Colocation.index(provider.id),
                                      className: css.item,
                                      children: [
                                        JsxRuntime.jsx("div", {
                                              children: "Products"
                                            }),
                                        JsxRuntime.jsx("div", {
                                              children: String(provider.totalProducts),
                                              className: css.total
                                            })
                                      ]
                                    }),
                                className: css.itemContainer
                              }) : null,
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(A.make, {
                                    href: provider.url,
                                    children: JsxRuntime.jsx("h4", {
                                          children: provider.name
                                        })
                                  }),
                              className: css.itemContainer
                            })
                      ],
                      className: css.itemsList
                    })
              ],
              className: Cx.cx([
                    css.wrapper,
                    className,
                    provider.active ? "" : css.inactive,
                    manageContext ? css.manageTileHeight : ""
                  ])
            });
}

var make = ProviderTile;

export {
  css ,
  Helpers ,
  make ,
}
/* css Not a pure module */
