import * as React from 'react';
import ReduxProvider from 'ReduxProvider';
import { connect } from 'react-redux';
import { setUserData } from '../../../bundles/Client/common/actions/userActions';

import ProvidersList from './ProvidersList.res.js';

const mapStateToProps = state => ({
  reduxProps: {
    isUserLoggedIn: !!state.user.id,
  },
});

const mapDispatchToProps = dispatch => ({
  reduxfunction: {
    setUserData: userData => {
      dispatch(setUserData(userData));
    },
  },
});

const ProvidersListContainer = connect(mapStateToProps, mapDispatchToProps)(ProvidersList);

export default props => {
  const { context, ...filteredProps } = props;
  return (
    <ReduxProvider {...props}>
      <ProvidersListContainer props={filteredProps} context={context} />
    </ReduxProvider>
  );
};
