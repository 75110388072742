// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Api from "../../api/Api.res.js";
import * as Role from "../../models/Role.res.js";
import * as Hooks from "../../libs/Hooks.res.js";
import * as React from "react";
import * as Button from "../../styleguide/components/Button/Button.res.js";
import * as $$String from "rescript/lib/es6/string.js";
import * as $$Promise from "../../bindings/Promise.res.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Provider from "../../models/Provider.res.js";
import * as TopNavbar from "../user-dashboard/top-navbar/TopNavbar.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as StatusType from "../../models/StatusType.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as SentryLogger from "../../loggers/SentryLogger.res.js";
import * as ReactDebounce from "rescript-debounce-react/src/ReactDebounce.res.js";
import * as AdminTopNavbar from "../dashboard/common/admin-top-navbar/AdminTopNavbar.res.js";
import * as DirectoryService from "../../models/DirectoryService.res.js";
import * as ProvidersListSeo from "./seo/ProvidersListSeo.res.js";
import * as ProvidersListGrid from "./grid/ProvidersListGrid.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProjectsViewContext from "../../models/ProjectsViewContext.res.js";
import * as ProvidersListHeader from "./header/ProvidersListHeader.res.js";
import * as ProvidersListScss from "./ProvidersList.scss";
import * as ProvidersListFilters from "./filters/ProvidersListFilters.res.js";
import * as ProviderPermissionType from "../../models/ProviderPermissionType.res.js";
import * as ProvidersListServiceCategory from "./ProvidersListServiceCategory.res.js";

var css = ProvidersListScss;

function ProvidersList$ProvidersList(props) {
  var directoryServices = props.directoryServices;
  var isNotOnlyProviderMember = props.isNotOnlyProviderMember;
  var isConcierge = props.isConcierge;
  var userRole = props.userRole;
  var serviceCategory = props.serviceCategory;
  var offset = props.offset;
  var currentPage = props.currentPage;
  var totalPages = props.totalPages;
  var totalProviders = props.totalProviders;
  var providers = props.providers;
  var mobile = props.mobile;
  var container = React.useRef(null);
  var searchProviders = ReactDebounce.useDebounced(300, (function (param) {
          param.dispatch({
                TAG: "PerformSearch",
                _0: param.state.search
              });
        }));
  var initialState = React.useMemo((function () {
          return {
                  providers: providers,
                  currentPage: currentPage,
                  totalProviders: totalProviders,
                  totalPages: totalPages,
                  offset: offset,
                  status: "Viewing",
                  sortBy: {
                    NAME: "Name",
                    VAL: "Asc"
                  },
                  viewAll: false,
                  search: "",
                  directoryServiceFilter: [],
                  permissionTypeFilter: [],
                  statusTypeFilter: [],
                  showFilter: false,
                  serviceCategory: serviceCategory
                };
        }), []);
  var match = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "FetchProviders" :
                  return {
                          TAG: "SideEffects",
                          _0: (function (param) {
                              var dispatch = param.dispatch;
                              var state = param.state;
                              $$Promise.wait(Api.fetchProvidersList(state.currentPage, state.search, state.sortBy, state.directoryServiceFilter, state.permissionTypeFilter, state.statusTypeFilter, state.viewAll, state.serviceCategory), (function (x) {
                                      if (x.TAG === "Ok") {
                                        return dispatch({
                                                    TAG: "SuccessProvidersFetch",
                                                    _0: x._0
                                                  });
                                      }
                                      SentryLogger.error1({
                                            rootModule: "ProvidersList",
                                            subModulePath: {
                                              hd: "ProvidersList",
                                              tl: /* [] */0
                                            },
                                            value: "make",
                                            fullPath: "ProvidersList.ProvidersList.make"
                                          }, "ProvidersList::FetchProviders::Error", [
                                            "Error",
                                            x._0
                                          ]);
                                      dispatch("FailProvidersFetch");
                                    }));
                            })
                        };
              case "FailProvidersFetch" :
                  return {
                          TAG: "Update",
                          _0: {
                            providers: state.providers,
                            currentPage: state.currentPage,
                            totalProviders: state.totalProviders,
                            totalPages: state.totalPages,
                            offset: state.offset,
                            status: "FailedFetch",
                            sortBy: state.sortBy,
                            viewAll: state.viewAll,
                            search: state.search,
                            directoryServiceFilter: state.directoryServiceFilter,
                            permissionTypeFilter: state.permissionTypeFilter,
                            statusTypeFilter: state.statusTypeFilter,
                            showFilter: false,
                            serviceCategory: state.serviceCategory
                          }
                        };
              case "ShowFilters" :
                  return {
                          TAG: "Update",
                          _0: {
                            providers: state.providers,
                            currentPage: state.currentPage,
                            totalProviders: state.totalProviders,
                            totalPages: state.totalPages,
                            offset: state.offset,
                            status: state.status,
                            sortBy: state.sortBy,
                            viewAll: state.viewAll,
                            search: state.search,
                            directoryServiceFilter: state.directoryServiceFilter,
                            permissionTypeFilter: state.permissionTypeFilter,
                            statusTypeFilter: state.statusTypeFilter,
                            showFilter: true,
                            serviceCategory: state.serviceCategory
                          }
                        };
              case "HideFilters" :
                  return {
                          TAG: "Update",
                          _0: {
                            providers: state.providers,
                            currentPage: state.currentPage,
                            totalProviders: state.totalProviders,
                            totalPages: state.totalPages,
                            offset: state.offset,
                            status: state.status,
                            sortBy: state.sortBy,
                            viewAll: state.viewAll,
                            search: state.search,
                            directoryServiceFilter: state.directoryServiceFilter,
                            permissionTypeFilter: state.permissionTypeFilter,
                            statusTypeFilter: state.statusTypeFilter,
                            showFilter: false,
                            serviceCategory: state.serviceCategory
                          }
                        };
              case "CancelFilters" :
                  return {
                          TAG: "Update",
                          _0: {
                            providers: state.providers,
                            currentPage: state.currentPage,
                            totalProviders: state.totalProviders,
                            totalPages: state.totalPages,
                            offset: state.offset,
                            status: state.status,
                            sortBy: state.sortBy,
                            viewAll: state.viewAll,
                            search: "",
                            directoryServiceFilter: [],
                            permissionTypeFilter: [],
                            statusTypeFilter: [],
                            showFilter: false,
                            serviceCategory: state.serviceCategory
                          }
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "PerformSearch" :
                  var match = state.status;
                  switch (match) {
                    case "Fetching" :
                        return "NoUpdate";
                    case "Viewing" :
                    case "FailedFetch" :
                        break;
                    
                  }
                  if (action._0 !== state.search || mobile) {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              providers: state.providers,
                              currentPage: 1,
                              totalProviders: state.totalProviders,
                              totalPages: state.totalPages,
                              offset: state.offset,
                              status: "Fetching",
                              sortBy: state.sortBy,
                              viewAll: state.viewAll,
                              search: state.search,
                              directoryServiceFilter: state.directoryServiceFilter,
                              permissionTypeFilter: state.permissionTypeFilter,
                              statusTypeFilter: state.statusTypeFilter,
                              showFilter: state.showFilter,
                              serviceCategory: state.serviceCategory
                            },
                            _1: (function (param) {
                                param.dispatch("FetchProviders");
                              })
                          };
                  }
              case "SuccessProvidersFetch" :
                  var res = action._0;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            providers: res.providers,
                            currentPage: res.currentPage,
                            totalProviders: res.totalProviders,
                            totalPages: res.totalPages,
                            offset: res.offset,
                            status: "Viewing",
                            sortBy: state.sortBy,
                            viewAll: state.viewAll,
                            search: state.search,
                            directoryServiceFilter: state.directoryServiceFilter,
                            permissionTypeFilter: state.permissionTypeFilter,
                            statusTypeFilter: state.statusTypeFilter,
                            showFilter: false,
                            serviceCategory: state.serviceCategory
                          },
                          _1: (function (param) {
                              Belt_Option.map(Caml_option.nullable_to_opt(container.current), (function (prim) {
                                      return prim.scrollTop;
                                    }));
                            })
                        };
              case "UpdatePage" :
                  var nextPage = action._0;
                  var match$1 = state.status;
                  switch (match$1) {
                    case "Fetching" :
                        return "NoUpdate";
                    case "Viewing" :
                    case "FailedFetch" :
                        break;
                    
                  }
                  if (state.currentPage !== nextPage) {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              providers: state.providers,
                              currentPage: nextPage,
                              totalProviders: state.totalProviders,
                              totalPages: state.totalPages,
                              offset: state.offset,
                              status: "Fetching",
                              sortBy: state.sortBy,
                              viewAll: state.viewAll,
                              search: state.search,
                              directoryServiceFilter: state.directoryServiceFilter,
                              permissionTypeFilter: state.permissionTypeFilter,
                              statusTypeFilter: state.statusTypeFilter,
                              showFilter: state.showFilter,
                              serviceCategory: state.serviceCategory
                            },
                            _1: (function (param) {
                                param.dispatch("FetchProviders");
                              })
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "SortBy" :
                  var match$2 = state.status;
                  switch (match$2) {
                    case "Fetching" :
                        return "NoUpdate";
                    case "Viewing" :
                    case "FailedFetch" :
                        break;
                    
                  }
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            providers: state.providers,
                            currentPage: state.currentPage,
                            totalProviders: state.totalProviders,
                            totalPages: state.totalPages,
                            offset: state.offset,
                            status: "Fetching",
                            sortBy: action._0,
                            viewAll: state.viewAll,
                            search: state.search,
                            directoryServiceFilter: state.directoryServiceFilter,
                            permissionTypeFilter: state.permissionTypeFilter,
                            statusTypeFilter: state.statusTypeFilter,
                            showFilter: state.showFilter,
                            serviceCategory: state.serviceCategory
                          },
                          _1: (function (param) {
                              param.dispatch("FetchProviders");
                            })
                        };
              case "ViewBy" :
                  var match$3 = state.status;
                  switch (match$3) {
                    case "Fetching" :
                        return "NoUpdate";
                    case "Viewing" :
                    case "FailedFetch" :
                        break;
                    
                  }
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            providers: state.providers,
                            currentPage: state.currentPage,
                            totalProviders: state.totalProviders,
                            totalPages: state.totalPages,
                            offset: state.offset,
                            status: "Fetching",
                            sortBy: state.sortBy,
                            viewAll: action._0,
                            search: state.search,
                            directoryServiceFilter: state.directoryServiceFilter,
                            permissionTypeFilter: state.permissionTypeFilter,
                            statusTypeFilter: state.statusTypeFilter,
                            showFilter: state.showFilter,
                            serviceCategory: state.serviceCategory
                          },
                          _1: (function (param) {
                              param.dispatch("FetchProviders");
                            })
                        };
              case "UpdateSearchInput" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            providers: state.providers,
                            currentPage: state.currentPage,
                            totalProviders: state.totalProviders,
                            totalPages: state.totalPages,
                            offset: state.offset,
                            status: state.status,
                            sortBy: state.sortBy,
                            viewAll: state.viewAll,
                            search: action._0,
                            directoryServiceFilter: state.directoryServiceFilter,
                            permissionTypeFilter: state.permissionTypeFilter,
                            statusTypeFilter: state.statusTypeFilter,
                            showFilter: state.showFilter,
                            serviceCategory: state.serviceCategory
                          },
                          _1: searchProviders
                        };
              case "ToggleDirectoryServiceFilter" :
                  var directoryServiceId = action._0;
                  var match$4 = state.status;
                  switch (match$4) {
                    case "Fetching" :
                        return "NoUpdate";
                    case "Viewing" :
                    case "FailedFetch" :
                        break;
                    
                  }
                  var directoryServiceFilter = action._1 ? Belt_Array.concat(state.directoryServiceFilter, [directoryServiceId]) : Belt_Array.keep(state.directoryServiceFilter, (function (value) {
                            return Caml_obj.notequal(value, directoryServiceId);
                          }));
                  if (mobile) {
                    return {
                            TAG: "Update",
                            _0: {
                              providers: state.providers,
                              currentPage: state.currentPage,
                              totalProviders: state.totalProviders,
                              totalPages: state.totalPages,
                              offset: state.offset,
                              status: state.status,
                              sortBy: state.sortBy,
                              viewAll: state.viewAll,
                              search: state.search,
                              directoryServiceFilter: directoryServiceFilter,
                              permissionTypeFilter: state.permissionTypeFilter,
                              statusTypeFilter: state.statusTypeFilter,
                              showFilter: state.showFilter,
                              serviceCategory: state.serviceCategory
                            }
                          };
                  } else {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              providers: state.providers,
                              currentPage: 1,
                              totalProviders: state.totalProviders,
                              totalPages: state.totalPages,
                              offset: state.offset,
                              status: "Fetching",
                              sortBy: state.sortBy,
                              viewAll: state.viewAll,
                              search: state.search,
                              directoryServiceFilter: directoryServiceFilter,
                              permissionTypeFilter: state.permissionTypeFilter,
                              statusTypeFilter: state.statusTypeFilter,
                              showFilter: state.showFilter,
                              serviceCategory: state.serviceCategory
                            },
                            _1: (function (param) {
                                param.dispatch("FetchProviders");
                              })
                          };
                  }
              case "TogglePermissionTypeFilter" :
                  var permissionTypeId = action._0;
                  var match$5 = state.status;
                  switch (match$5) {
                    case "Fetching" :
                        return "NoUpdate";
                    case "Viewing" :
                    case "FailedFetch" :
                        break;
                    
                  }
                  var permissionTypeFilter = action._1 ? Belt_Array.concat(state.permissionTypeFilter, [permissionTypeId]) : Belt_Array.keep(state.permissionTypeFilter, (function (value) {
                            return Caml_obj.notequal(value, permissionTypeId);
                          }));
                  if (mobile) {
                    return {
                            TAG: "Update",
                            _0: {
                              providers: state.providers,
                              currentPage: state.currentPage,
                              totalProviders: state.totalProviders,
                              totalPages: state.totalPages,
                              offset: state.offset,
                              status: state.status,
                              sortBy: state.sortBy,
                              viewAll: state.viewAll,
                              search: state.search,
                              directoryServiceFilter: state.directoryServiceFilter,
                              permissionTypeFilter: permissionTypeFilter,
                              statusTypeFilter: state.statusTypeFilter,
                              showFilter: state.showFilter,
                              serviceCategory: state.serviceCategory
                            }
                          };
                  } else {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              providers: state.providers,
                              currentPage: 1,
                              totalProviders: state.totalProviders,
                              totalPages: state.totalPages,
                              offset: state.offset,
                              status: "Fetching",
                              sortBy: state.sortBy,
                              viewAll: state.viewAll,
                              search: state.search,
                              directoryServiceFilter: state.directoryServiceFilter,
                              permissionTypeFilter: permissionTypeFilter,
                              statusTypeFilter: state.statusTypeFilter,
                              showFilter: state.showFilter,
                              serviceCategory: state.serviceCategory
                            },
                            _1: (function (param) {
                                param.dispatch("FetchProviders");
                              })
                          };
                  }
              case "ToggleStatusTypeFilter" :
                  var statusTypeId = action._0;
                  var match$6 = state.status;
                  switch (match$6) {
                    case "Fetching" :
                        return "NoUpdate";
                    case "Viewing" :
                    case "FailedFetch" :
                        break;
                    
                  }
                  var statusTypeFilter = action._1 ? Belt_Array.concat(state.statusTypeFilter, [statusTypeId]) : Belt_Array.keep(state.statusTypeFilter, (function (value) {
                            return Caml_obj.notequal(value, statusTypeId);
                          }));
                  if (mobile) {
                    return {
                            TAG: "Update",
                            _0: {
                              providers: state.providers,
                              currentPage: state.currentPage,
                              totalProviders: state.totalProviders,
                              totalPages: state.totalPages,
                              offset: state.offset,
                              status: state.status,
                              sortBy: state.sortBy,
                              viewAll: state.viewAll,
                              search: state.search,
                              directoryServiceFilter: state.directoryServiceFilter,
                              permissionTypeFilter: state.permissionTypeFilter,
                              statusTypeFilter: statusTypeFilter,
                              showFilter: state.showFilter,
                              serviceCategory: state.serviceCategory
                            }
                          };
                  } else {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              providers: state.providers,
                              currentPage: 1,
                              totalProviders: state.totalProviders,
                              totalPages: state.totalPages,
                              offset: state.offset,
                              status: "Fetching",
                              sortBy: state.sortBy,
                              viewAll: state.viewAll,
                              search: state.search,
                              directoryServiceFilter: state.directoryServiceFilter,
                              permissionTypeFilter: state.permissionTypeFilter,
                              statusTypeFilter: statusTypeFilter,
                              showFilter: state.showFilter,
                              serviceCategory: state.serviceCategory
                            },
                            _1: (function (param) {
                                param.dispatch("FetchProviders");
                              })
                          };
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = ProvidersListServiceCategory.fromString(state.serviceCategory);
  var tmp;
  if (match$1 === "Manage") {
    switch (props.userViewContext) {
      case "Admin" :
          switch (userRole) {
            case "Admin" :
                tmp = JsxRuntime.jsx(AdminTopNavbar.make, {
                      selectedCategory: "Profiles",
                      isAdmin: true,
                      isConcierge: isConcierge,
                      isNotOnlyProviderMember: isNotOnlyProviderMember
                    });
                break;
            case "Provider" :
                tmp = JsxRuntime.jsx(AdminTopNavbar.make, {
                      selectedCategory: isConcierge ? "ConciergeProjects" : "Profiles",
                      isAdmin: false,
                      isConcierge: isConcierge,
                      isNotOnlyProviderMember: isNotOnlyProviderMember
                    });
                break;
            default:
              tmp = null;
          }
          break;
      case "Personal" :
      case "Visitor" :
          tmp = JsxRuntime.jsx(TopNavbar.make, {
                selectedCategory: "Projects"
              });
          break;
      
    }
  } else {
    tmp = null;
  }
  var tmp$1;
  if (!mobile || !state.showFilter) {
    var match$2 = $$String.lowercase_ascii(state.serviceCategory);
    var tmp$2 = match$2 === "manage" ? null : JsxRuntime.jsx(ProvidersListSeo.make, {
            serviceCategory: state.serviceCategory
          });
    tmp$1 = JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            JsxRuntime.jsx(ProvidersListGrid.make, {
                  providers: state.providers,
                  totalProviders: state.totalProviders,
                  currentPage: state.currentPage,
                  totalPages: state.totalPages,
                  offset: state.offset,
                  sortBy: state.sortBy,
                  viewAll: state.viewAll,
                  onSelect: (function (value) {
                      dispatch({
                            TAG: "SortBy",
                            _0: value
                          });
                    }),
                  onPageClick: (function (pageNum) {
                      dispatch({
                            TAG: "UpdatePage",
                            _0: pageNum
                          });
                    }),
                  onViewAllClick: (function () {
                      dispatch({
                            TAG: "ViewBy",
                            _0: !state.viewAll
                          });
                    }),
                  showAgreementCheck: props.showAgreementCheck,
                  serviceCategory: state.serviceCategory,
                  userRole: userRole
                }),
            tmp$2
          ]
        });
  } else {
    tmp$1 = JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            JsxRuntime.jsx("div", {
                  children: JsxRuntime.jsx(ProvidersListFilters.make, {
                        search: state.search,
                        facilityTypes: directoryServices,
                        facilityTypeFilter: state.directoryServiceFilter,
                        onChangeSearch: (function (value) {
                            dispatch({
                                  TAG: "UpdateSearchInput",
                                  _0: value
                                });
                          }),
                        onChangeFacilityType: (function (value, checked) {
                            dispatch({
                                  TAG: "ToggleDirectoryServiceFilter",
                                  _0: value,
                                  _1: checked
                                });
                          })
                      }),
                  className: css.mobileFilters
                }),
            JsxRuntime.jsxs("div", {
                  children: [
                    JsxRuntime.jsx(Button.make, {
                          size: "SM",
                          color: "Gray",
                          expanded: true,
                          className: css.mobileFiltersActionBarButton,
                          onClick: (function (param) {
                              dispatch("CancelFilters");
                            }),
                          children: "Cancel"
                        }),
                    JsxRuntime.jsx(Button.make, {
                          size: "SM",
                          color: "Teal",
                          expanded: true,
                          className: css.mobileFiltersActionBarButton,
                          onClick: (function (param) {
                              dispatch("FetchProviders");
                            }),
                          children: "Apply Filters"
                        })
                  ],
                  className: css.mobileFiltersActionBar
                })
          ]
        });
  }
  return JsxRuntime.jsxs("div", {
              children: [
                tmp,
                JsxRuntime.jsx(ProvidersListHeader.make, {
                      mobile: mobile,
                      search: state.search,
                      facilityTypes: directoryServices,
                      facilityTypeFilter: state.directoryServiceFilter,
                      permissionTypes: props.permissionTypes,
                      permissionTypeFilter: state.permissionTypeFilter,
                      statusTypes: props.statusTypes,
                      statusTypeFilter: state.statusTypeFilter,
                      onChangeSearch: (function (value) {
                          dispatch({
                                TAG: "UpdateSearchInput",
                                _0: value
                              });
                        }),
                      onChangeFacilityType: (function (value, checked) {
                          dispatch({
                                TAG: "ToggleDirectoryServiceFilter",
                                _0: value,
                                _1: checked
                              });
                        }),
                      onChangePermissionType: (function (value, checked) {
                          dispatch({
                                TAG: "TogglePermissionTypeFilter",
                                _0: value,
                                _1: checked
                              });
                        }),
                      onChangeStatusType: (function (value, checked) {
                          dispatch({
                                TAG: "ToggleStatusTypeFilter",
                                _0: value,
                                _1: checked
                              });
                        }),
                      showFilter: state.showFilter,
                      onClickFilterButton: (function (param) {
                          dispatch("ShowFilters");
                        }),
                      serviceCategory: state.serviceCategory,
                      userRole: userRole
                    }),
                tmp$1
              ],
              ref: Caml_option.some(container),
              className: Cx.cx([
                    css.layout,
                    mobile ? css.mobileLayout : ""
                  ])
            });
}

var ProvidersList = {
  css: css,
  make: ProvidersList$ProvidersList
};

function ProvidersList$default(props) {
  var context = props.context;
  var props$1 = props.props;
  return JsxRuntime.jsx(ProvidersList$ProvidersList, {
              mobile: context.mobile,
              providers: Belt_Array.map(props$1.providers, Provider.fromJs),
              totalProviders: props$1.totalProviders,
              totalPages: props$1.totalPages,
              currentPage: props$1.currentPage,
              offset: props$1.offset,
              permissionTypes: Belt_Array.map(props$1.permissionTypes, ProviderPermissionType.fromJs),
              statusTypes: Belt_Array.map(props$1.statusTypes, StatusType.fromJs),
              showAgreementCheck: props$1.showAgreementCheck,
              serviceCategory: props$1.serviceCategory,
              userRole: Role.fromString(context.userRole),
              userViewContext: ProjectsViewContext.fromString(props$1.viewContext),
              isConcierge: props$1.isConcierge,
              isNotOnlyProviderMember: props$1.isNotOnlyProviderMember,
              directoryServices: Belt_Array.map(props$1.directoryServices, DirectoryService.Filter.fromJs)
            });
}

var $$default = ProvidersList$default;

export {
  ProvidersList ,
  $$default as default,
}
/* css Not a pure module */
