// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as Button from "../../../styleguide/components/Button/Button.res.js";
import * as Control from "../../../styleguide/components/Control/Control.res.js";
import * as Popover from "../../../styleguide/components/Popover/Popover.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as IconSelect from "../../../styleguide/icons/IconSelect.res.js";
import * as Pagination from "../../../styleguide/components/Pagination/Pagination.res.js";
import * as ProviderTile from "../../common/providers/ProviderTile.res.js";
import * as Routes_Provider from "../../../routes/common/Routes_Provider.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProvidersListScss from "../ProvidersList.scss";
import * as ProvidersListServiceCategory from "../ProvidersListServiceCategory.res.js";

var css = ProvidersListScss;

var phantomProviders = [
  1,
  2,
  3,
  4
];

function ProvidersListGrid(props) {
  var userRole = props.userRole;
  var serviceCategory = props.serviceCategory;
  var showAgreementCheck = props.showAgreementCheck;
  var onViewAllClick = props.onViewAllClick;
  var onSelect = props.onSelect;
  var offset = props.offset;
  var totalPages = props.totalPages;
  var totalProviders = props.totalProviders;
  var providers = props.providers;
  var tmp;
  tmp = ProvidersListServiceCategory.showManage(serviceCategory) && userRole === "Admin" ? JsxRuntime.jsx("div", {
          children: JsxRuntime.jsx("div", {
                children: JsxRuntime.jsx(Button.AsNativeLink.make, {
                      href: Routes_Provider.Dashboard.$$new,
                      size: "MD",
                      color: "Teal",
                      children: "Add New Provider"
                    }),
                className: css.addNewButton
              }),
          className: css.gridHeader
        }) : null;
  return JsxRuntime.jsxs("div", {
              children: [
                tmp,
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: totalProviders !== 1 ? String(totalProviders) + " Providers" : "1 Provider",
                              className: css.providersCount
                            }),
                        JsxRuntime.jsxs(Popover.make, {
                              className: css.headerButtonContainer,
                              children: [
                                JsxRuntime.jsxs(Popover.Trigger.make, {
                                      className: css.popoverTrigger,
                                      children: [
                                        JsxRuntime.jsx(Control.AsLink.make, {
                                              color: "Gray",
                                              onClick: (function (param) {
                                                  
                                                }),
                                              children: props.sortBy.VAL === "Desc" ? "Sort by Name, Z to A" : "Sort by Name, A to Z"
                                            }),
                                        JsxRuntime.jsx(IconSelect.make, {
                                              size: "XXXS",
                                              color: "GrayText"
                                            })
                                      ]
                                    }),
                                JsxRuntime.jsxs(Popover.Body.make, {
                                      position: {
                                        TAG: "Below",
                                        _0: "RightEdge"
                                      },
                                      className: css.popoverBody,
                                      children: [
                                        JsxRuntime.jsx("div", {
                                              children: JsxRuntime.jsx("div", {
                                                    children: "Name, A-Z",
                                                    className: css.sortSelection,
                                                    onClick: (function (param) {
                                                        onSelect({
                                                              NAME: "Name",
                                                              VAL: "Asc"
                                                            });
                                                      })
                                                  }),
                                              className: css.popoverRow
                                            }),
                                        JsxRuntime.jsx("div", {
                                              children: JsxRuntime.jsx("div", {
                                                    children: "Name, Z-A",
                                                    className: css.sortSelection,
                                                    onClick: (function (param) {
                                                        onSelect({
                                                              NAME: "Name",
                                                              VAL: "Desc"
                                                            });
                                                      })
                                                  }),
                                              className: css.popoverRow
                                            })
                                      ]
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: props.viewAll ? "View By Page" : "View All",
                                      className: css.viewAllButton,
                                      onClick: (function (param) {
                                          onViewAllClick();
                                        })
                                    })
                              ]
                            })
                      ],
                      className: css.gridHeader
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        Belt_Array.map(providers, (function (provider) {
                                return JsxRuntime.jsx(ProviderTile.make, {
                                            provider: provider,
                                            className: css.providerTile,
                                            deferImage: true,
                                            manageContext: ProvidersListServiceCategory.showManage(serviceCategory),
                                            showAgreementCheck: showAgreementCheck,
                                            userRole: userRole
                                          }, ID.toString(provider.id));
                              })),
                        Belt_Array.map(phantomProviders, (function (phantom) {
                                return JsxRuntime.jsx("div", {
                                            className: css.phantomProvider
                                          }, String(phantom));
                              }))
                      ],
                      className: css.gridBody
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        totalPages > 1 ? JsxRuntime.jsx(Pagination.make, {
                                currentPage: props.currentPage,
                                totalPages: totalPages,
                                onPageClick: props.onPageClick
                              }) : null,
                        JsxRuntime.jsx("div", {
                              children: totalPages < 2 ? (
                                  totalProviders !== 0 ? (
                                      totalProviders !== 1 ? "Showing all" : "Showing 1"
                                    ) : "No providers found"
                                ) : "Showing " + (String(offset + 1 | 0) + (" to " + (String(offset + providers.length | 0) + (" of " + String(totalProviders))))),
                              className: css.paginationInfo
                            })
                      ],
                      className: css.paginationBar
                    })
              ],
              className: css.listContainer
            });
}

var make = ProvidersListGrid;

export {
  css ,
  phantomProviders ,
  make ,
}
/* css Not a pure module */
