// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as Label from "../../../styleguide/forms/Label/Label.res.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Checkbox from "../../../styleguide/forms/Checkbox/Checkbox.res.js";
import * as Dropdown from "../../../styleguide/forms/Dropdown/Dropdown.res.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as SearchField from "../../../styleguide/forms/SearchField/SearchField.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProvidersListScss from "../ProvidersList.scss";

var css = ProvidersListScss;

function ProvidersListFiltersManage(props) {
  var onChangeStatusType = props.onChangeStatusType;
  var onChangePermissionType = props.onChangePermissionType;
  var onChangeFacilityType = props.onChangeFacilityType;
  var onChangeSearch = props.onChangeSearch;
  var statusTypeFilter = props.statusTypeFilter;
  var permissionTypeFilter = props.permissionTypeFilter;
  var facilityTypeFilter = props.facilityTypeFilter;
  var tmp;
  if (props.userRole === "Admin") {
    var x = facilityTypeFilter.length;
    var x$1 = permissionTypeFilter.length;
    var x$2 = statusTypeFilter.length;
    tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            JsxRuntime.jsxs(Dropdown.make, {
                  children: [
                    JsxRuntime.jsx(Dropdown.Trigger.make, {
                          className: css.dropdownTrigger,
                          iconSize: "XS",
                          iconColor: "Gray",
                          children: x !== 0 ? (
                              x !== 1 ? String(x) + " Types" : "1 Type"
                            ) : "Directory Type"
                        }),
                    JsxRuntime.jsx(Dropdown.Body.make, {
                          position: {
                            TAG: "Below",
                            _0: "LeftEdge"
                          },
                          className: css.dropdownBody,
                          children: Belt_Array.map(props.facilityTypes, (function (facilityType) {
                                  var id = "providers-list-filter--facility-type-" + ID.toString(facilityType.id);
                                  return JsxRuntime.jsxs("div", {
                                              children: [
                                                JsxRuntime.jsx(Checkbox.make, {
                                                      id: id,
                                                      size: "SM",
                                                      checked: (function (__x) {
                                                            return Js_array.findIndex((function (value) {
                                                                          return Caml_obj.equal(value, facilityType.id);
                                                                        }), __x);
                                                          })(facilityTypeFilter) > -1,
                                                      onChange: (function ($$event) {
                                                          onChangeFacilityType(facilityType.id, $$event.target.checked);
                                                        })
                                                    }),
                                                JsxRuntime.jsx(Label.make, {
                                                      forId: id,
                                                      className: css.label,
                                                      children: facilityType.title
                                                    })
                                              ],
                                              className: css.dropdownBodyRow
                                            }, id);
                                }))
                        })
                  ]
                }),
            JsxRuntime.jsxs(Dropdown.make, {
                  children: [
                    JsxRuntime.jsx(Dropdown.Trigger.make, {
                          className: css.dropdownTrigger,
                          iconSize: "XS",
                          iconColor: "Gray",
                          children: x$1 !== 0 ? (
                              x$1 !== 1 ? String(x$1) + " Permissions" : "1 Permission"
                            ) : "Permissions"
                        }),
                    JsxRuntime.jsx(Dropdown.Body.make, {
                          position: {
                            TAG: "Below",
                            _0: "LeftEdge"
                          },
                          className: css.dropdownBody,
                          children: Belt_Array.map(props.permissionTypes, (function (permissionType) {
                                  var id = "providers-list-filter--permission-type-" + ID.toString(permissionType.id);
                                  return JsxRuntime.jsxs("div", {
                                              children: [
                                                JsxRuntime.jsx(Checkbox.make, {
                                                      id: id,
                                                      size: "SM",
                                                      checked: (function (__x) {
                                                            return Js_array.findIndex((function (value) {
                                                                          return Caml_obj.equal(value, permissionType.id);
                                                                        }), __x);
                                                          })(permissionTypeFilter) > -1,
                                                      onChange: (function ($$event) {
                                                          onChangePermissionType(permissionType.id, $$event.target.checked);
                                                        })
                                                    }),
                                                JsxRuntime.jsx(Label.make, {
                                                      forId: id,
                                                      className: css.label,
                                                      children: permissionType.displayName
                                                    })
                                              ],
                                              className: css.dropdownBodyRow
                                            }, id);
                                }))
                        })
                  ]
                }),
            JsxRuntime.jsxs(Dropdown.make, {
                  children: [
                    JsxRuntime.jsx(Dropdown.Trigger.make, {
                          className: css.dropdownTrigger,
                          iconSize: "XS",
                          iconColor: "Gray",
                          children: x$2 !== 0 ? (
                              x$2 !== 1 ? String(x$2) + " Statuses" : "1 Status"
                            ) : "Statuses"
                        }),
                    JsxRuntime.jsx(Dropdown.Body.make, {
                          position: {
                            TAG: "Below",
                            _0: "LeftEdge"
                          },
                          className: css.dropdownBody,
                          children: Belt_Array.map(props.statusTypes, (function (statusType) {
                                  var id = "providers-list-filter--status-type-" + ID.toString(statusType.id);
                                  return JsxRuntime.jsxs("div", {
                                              children: [
                                                JsxRuntime.jsx(Checkbox.make, {
                                                      id: id,
                                                      size: "SM",
                                                      checked: (function (__x) {
                                                            return Js_array.findIndex((function (value) {
                                                                          return Caml_obj.equal(value, statusType.id);
                                                                        }), __x);
                                                          })(statusTypeFilter) > -1,
                                                      onChange: (function ($$event) {
                                                          onChangeStatusType(statusType.id, $$event.target.checked);
                                                        })
                                                    }),
                                                JsxRuntime.jsx(Label.make, {
                                                      forId: id,
                                                      className: css.label,
                                                      children: statusType.displayName
                                                    })
                                              ],
                                              className: css.dropdownBodyRow
                                            }, id);
                                }))
                        })
                  ]
                })
          ]
        });
  } else {
    tmp = null;
  }
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(SearchField.make, {
                      id: "providers-list-search",
                      value: props.search,
                      placeholder: "Search by provider name",
                      inputClassName: css.searchField,
                      iconClassName: css.searchIcon,
                      onChange: (function ($$event) {
                          onChangeSearch($$event.target.value);
                        })
                    }),
                tmp
              ]
            });
}

var make = ProvidersListFiltersManage;

export {
  css ,
  make ,
}
/* css Not a pure module */
